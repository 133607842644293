<template>
  <div class="menu-item" v-on:click.stop="show = !show">
    <div class="dropdown">
      <slot name="dropdown"></slot>
    </div>
    <transition name="fade" appear>
      <div v-show="show">
        <slot name="dropdown-trage"></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'Dropdown',

  data () {
    return {
      show: false
    }
  },

  mounted(){
    document.body.addEventListener('click',()=>{
        this.show = false;
    },false);
  }
}
</script>

<style scoped>
.menu-item {
  position: relative;
}

.fade-enter-active,
.fade-leave-active {
  transition: all .5s ease-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>