import { create, all } from 'mathjs'

const config = {
  number: 'BigNumber'
}
const mathjs = create(all, config)

/**
 * 加法
 * @param arg1
 * @param arg2
 * @returns
 */
function Add(arg1, arg2) {
  arg2 = parseFloat(arg2);
  let r1, r2, m;
  try {
    r1 = arg1.toString().split(".")[1].length
  } catch (e) {
    r1 = 0
  }
  try {
    r2 = arg2.toString().split(".")[1].length
  } catch (e) {
    r2 = 0
  }
  m = Math.pow(100, Math.max(r1, r2));
  return (this.Mul(arg1, m) + this.Mul(arg2, m)) / m;
}
 
/**
 * 减法
 * @param arg1
 * @param arg2
 * @returns
 */
function Sub(arg1, arg2){
  arg1 = parseFloat(arg1);
  arg2 = parseFloat(arg2);
  let r1, r2, m, n;
  try {
    r1 = arg1.toString().split(".")[1].length
  } catch (e) {
    r1 = 0
  }
  try {
    r2 = arg2.toString().split(".")[1].length
  } catch (e) {
    r2 = 0
  }
  m = Math.pow(10, Math.max(r1, r2));
  //动态控制精度长度
  n = (r1 >= r2) ? r1 : r2;
  return ((this.Mul(arg1, m) - this.Mul(arg2, m)) / m).toFixed(n);
}
 
/***
 * 乘法，获取精确乘法的结果值
 * @param arg1
 * @param arg2
 * @returns
 */
function Mul(arg1, arg2) {
  arg1 = parseFloat(arg1);
  arg2 = parseFloat(arg2);
  let m = 0,
    s1 = arg1.toString(),
    s2 = arg2.toString();
  try {
    m += s1.split(".")[1].length
  } catch (e) {''}
  try {
    m += s2.split(".")[1].length
  } catch (e) {''}
  return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m);
}
 
/***
 * 除法，获取精确乘法的结果值
 * @param arg1
 * @param arg2
 * @returns
 */
// function Div(arg1, arg2){
//   arg1 = parseFloat(arg1);
//   arg2 = parseFloat(arg2);
//   let t1 = 0,
//     t2 = 0,
//     r1, r2;
//   try {
//     t1 = arg1.toString().split(".")[1].length;
//   } catch (e) {''}
//   try {
//     t2 = arg2.toString().split(".")[1].length;
//   } catch (e) {''}
//   r1 = Number(arg1.toString().replace(".", ""));
//   r2 = Number(arg2.toString().replace(".", ""));
//   return this.Mul(r1 / r2, Math.pow(10, t2 - t1));
// }

function Div(arg1, arg2){
  arg1 = arg1 ? arg1 : 0
  arg2 = arg2 ? arg2 : 0
  let res = mathjs.divide(mathjs.bignumber(arg1), mathjs.bignumber(arg2))
  if (isNaN(res)) {
    res = 0
  }
  if (!isFinite(res)) {
    return '-'
  }
  return mathjs.round(res, 2)
}

function formatMoney(number, decimals = 0, decPoint = '.', thousandsSep = ',') {
  /**
   * @description 格式化金额
   * @param number：要格式化的数字
   * @param decimals：保留几位小数 默认0位
   * @param decPoint：小数点符号 默认.
   * @param thousandsSep：千分位符号 默认为,
   */
  number = (number + '').replace(/[^0-9+-Ee.]/g, '')
  let n = !isFinite(+number) ? 0 : +number
  let prec = !isFinite(+decimals) ? 0 : Math.abs(decimals)
  let sep = (typeof thousandsSep === 'undefined') ? ',' : thousandsSep
  let dec = (typeof decPoint === 'undefined') ? '.' : decPoint
  let s = ''
  let toFixedFix = function (n, prec) {
    let k = Math.pow(10, prec)
    return '' + Math.ceil(n * k) / k
  }
  s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.')
  let re = /(-?\d+)(\d{3})/
  while (re.test(s[0])) {
    s[0] = s[0].replace(re, '$1' + sep + '$2')
  }
  if ((s[1] || '').length < prec) {
    s[1] = s[1] || ''
    s[1] += new Array(prec - s[1].length + 1).join('0')
  }
  return s.join(dec)
}

function smallToBig(n) {
  let fraction = ['角', '分'];
  let digit = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];
  let unit = [['元', '万', '亿'], ['', '拾', '佰', '仟']];
  
  let head = n < 0 ? '负' : '';
  
  n = Math.abs(n);
  
  let s = '';
  
  for (let i = 0; i < fraction.length; i++) {
    s += (digit[Math.floor(n * 10 * Math.pow(10, i)) % 10] + fraction[i]).replace(/零./, '');
  }
  s = s || '整';
  n = Math.floor(n);
  
  for (let i = 0; i < unit[0].length && n > 0; i++) {
    let p = '';
    for (let j = 0; j <= unit[i].length && n > 0; j++) {
      p = digit[n % 10] + unit[1][j] + p;
      n = Math.floor(n / 10);
    }
    s = p.replace(/(零.)*零$/, '').replace(/^$/, '零') + unit[0][i] + s;
  }
  return head + s.replace(/(零.)*零元/, '元').replace(/(零.)+/g, '零').replace(/^整$/, '零元整');
}

export default {
  Add,
  Sub,
  Mul,
  Div,
  formatMoney,
  smallToBig
}