import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios';
import math from '@/utils/math.js';
import VueTippy from 'vue-tippy'

// 自定义引入全域组件
import Loading from '@/components/Loading.vue'
import ShowMessage from '@/components/ShowMessage.vue'
import ShowMenu from '@/components/ShowMenu.vue'

// 自定义全域插件
import VPagination from "@hennge/vue3-pagination";
import { vfmPlugin } from 'vue-final-modal'
import Toaster from '@meforma/vue-toaster';

// 自定义全域Css
import '@/assets/css/select.css';
import "@/assets/css/pagination.css";
import 'v-calendar/dist/style.css';
import '@/assets/css/style.css';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';

// 本地调用
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster();

const app = createApp(App);

// 自定义全域变量
axios.interceptors.response.use(data=> {
	return data;
},
err=> {
	if (err.response.status == 401) {
		router.push({name: 'Login'})
		toaster.error(err.response.data.message)
	}
	return Promise.resolve(err);
})
app.config.globalProperties.$axios = axios
app.config.globalProperties.$math = math

// 自定义使用全域组件
app.component('Loading', Loading);
app.component('VPagination', VPagination);
app.component('ShowMessage', ShowMessage);
app.component('ShowMenu', ShowMenu);

app.use(store).use(router).use(vfmPlugin).use(Toaster).use(VueTippy).mount('#app')
