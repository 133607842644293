<template>
  <div class="loading">
    <div class="loading-content">
      <img src="/static/images/icon/loading.gif" width="32">
    </div>
  </div>
</template>

<script>
export default {
  name: "Loading",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.loading {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, .5);
  z-index: 9999;
}
.loading-content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
