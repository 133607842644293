<template>

	<vue-final-modal
	v-bind="$attrs"
	classes="modal-container"
	content-class="modal-content"
	:transition="{
		'enter-active-class': 'menu-enter-active',
    'enter-class': 'menu-enter',
    'enter-to-class': 'menu-enter-to',
    'leave-active-class': 'menu-leave-active',
    'leave-to-class': 'menu-leave-to',
    'leave-class': 'menu-leave'
  }"
	>
		<template v-slot="{ params }">
			<h2 class="text-center">{{ params.title }}</h2>
			<div class="modal__content">
				<div class="grid">
					<template v-for="param in params.app" :key="param">
						<div class="grid-card" v-if="param.meta.page">
							<div class="grid-card-content" @click="handlerClick(param.name)">
								<div class="submenu-bg">
									<img class="icon-64 m-auto" :src="param.meta.icon">
								</div>
									<div>{{ param.meta.name }}</div>
							</div>
						</div>
					</template>
				</div>
			</div>
		</template>
	</vue-final-modal>
</template>

<script>
export default {
	name: "ShowMenu",

	methods: {
		close() {
			this.$vfm.hideAll()
		},

		handlerClick(name) {
			this.close()
			this.$router.push({name: name})
		}
	}
}
</script>

<style scoped>
::v-deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep(.modal-content) {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 400px;
  max-height: 90%;
  padding: 1rem;
  background: rgba(255, 255, 255, .2);
  backdrop-filter: blur(20px);
  border-radius: 32px;
}
.modal__content {
	min-height: 300px;
	border-radius: 8px;
  flex-grow: 1;
  overflow-y: auto;
}
.modal__action {
  display: flex;
  justify-content: right;
  align-items: center;
  flex-shrink: 0;
}

.grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.grid-card {
	width: 33.333%;
	padding: 12px 0;
	text-align: center;
}
.submenu-bg {
	padding: 8px;
	border-radius: 12px;
	background-color: #333;
	display: inline-block;
}


</style>

<style scoped>
.dark-mode div ::v-deep(.modal-content) {
  border-color: #2d3748;
  background-color: #1a202c;
}
</style>