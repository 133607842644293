<template>
  <div class="block">
    <div class="d-flex justify-content-center align-items-center h-100">
      <form @submit.prevent="submit">
        <div class="card card-login">
          <div>
            <input type="text" class="form-control mb-2" placeholder="用户名" autocomplete v-model="username">
            <input type="password" class="form-control mb-2" placeholder="密码" autocomplete v-model="password">
          </div>
          <div class="mb-2 p-1 bg-38">
            <div class="d-flex justify-content-between align-items-top">
              <img class="mb-12p" :src="captchaImage">
              <button class="btn btn-refresh" type="button" @click="getCaptcha">
                <img class="icon-16" src="/static/images/icon/refresh.svg">
              </button>
            </div>
            <input type="text" class="form-control bg-65 mb-12p" placeholder="验证码" autocomplete v-model="captcha">
          </div>
          <button class="btn btn-blue w-100" type="submit">登录</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Login',

  setup() {
    const captcha_id = Number(Math.random().toString().substr(3,10) + Date.now()).toString(36);
    return {
      captcha_id
    }
  },

  data() {
    return {
      username: '',
      password: '',
      captcha: '',
      captchaImage: 'https://api.donlei.net/api/captcha?captcha_id='+this.captcha_id
    }
  },

  mounted() {
    //this.getCaptcha()
  },

  beforeUnmount() {

  },

  methods: {
    login() {
      this.$axios.post('/api/login', {
        username: this.username,
        password: this.password,
        captcha: this.captcha,
        captcha_id: this.captcha_id
      })
      .then(res => {
        if (res.status == 200) {
          if (res.data.success) {
            //this.token = res.data.data.username
            sessionStorage.setItem('Authorization', JSON.stringify(res.data.data));
            console.log(res.data)
            this.$toast.success(res.data.message)
            this.$router.push({name: 'Launcher'})
          } else {
            this.getCaptcha()
            this.captcha = ''
            this.$toast.error(res.data.message)
          }
        }
      })
      .catch(error => {
        console.log('error:', error)
      })
    },

    getCaptcha() {
      this.captchaImage = this.captchaImage + '&' + Math.random()
    },

    captchaId(){
      return Number(Math.random().toString().substr(3,10) + Date.now()).toString(36);
    },

    submit() {
      try {
        if (!this.username) throw '请填写用户名';
        if (!this.password) throw '请填写密码';
        if (!this.captcha) throw '请填写验证码';

        this.login();
      } catch(error) {
        this.$toast.error(error)
      }
    }
  }

}
</script>

<style scoped>
.card {
  width: 400px;
  min-height: 380px;
  background-color: #252525;
}
.card-login {
  padding: 48px 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.btn-refresh {
  padding: 12px;
  background-color: #656565;
}
</style>
