<template>

	<vue-final-modal v-bind="$attrs" classes="modal-container" content-class="modal-content">
		<template v-slot="{ params }">
			<span class="modal__title">
				<slot name="title"></slot>
			</span>
			<div class="modal__content">
				<slot v-bind:params="params"></slot>
			</div>
			<div class="modal__action">
				<button class="btn-blue mr-2" @click="$emit('confirm', close)">确定</button>
				<button @click="$emit('cancel', close)">取消</button>
			</div>
			<button class="modal__close btn btn-bg-none btn-close" @click="close">
        <img class="icon-16" src="/static/images/icon/close.svg">
			</button>
		</template>
	</vue-final-modal>
</template>

<script>
export default {
	name: "ShowMessage",

	methods: {
		close() {
			this.$vfm.hideAll()
		}
	}
}
</script>

<style scoped>
::v-deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep(.modal-content) {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 400px;
  max-height: 90%;
  padding: 1rem;
  background: #252525;
}
.modal__title {
  margin: 0 2rem 0 0;
  font-size: 16px;
}
.modal__content {
	margin: 32px 0;
  flex-grow: 1;
  overflow-y: auto;
}
.modal__action {
  display: flex;
  justify-content: right;
  align-items: center;
  flex-shrink: 0;
}
.modal__action button {
	color: #f5f5f5;
	padding: 8px 32px;
	border: none;
	background-color: #383838;
	cursor: pointer;
}
.modal__action button.btn-blue {
  color: #fff;
  background-color: #004bff;
}
.modal__close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}
</style>

<style scoped>
.dark-mode div ::v-deep(.modal-content) {
  border-color: #2d3748;
  background-color: #1a202c;
}
</style>