import { createRouter, createWebHistory } from 'vue-router'
import Launcher from '../views/Launcher.vue'
import Login from '../views/Login.vue';

import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster();

const routes = [
  {
    path: '/',
    name: 'Launcher',
    component: Launcher
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      name: "登陆页",
      icon: "/static/images/icon/order.png"
    }
  },

  { // 订单模块
    path: '/order',
    name: 'Order',
    component: () => import('../views/order/Order.vue'),
    meta: {
      name: '销售',
      icon: '/static/images/icon/order.png',
    },
    children: [
      {
        path: 'edit',
        name: 'OrderEdit',
        components: {order: () => import('../views/order/OrderEdit.vue')},
        meta: {
          name: '开销售单',
          icon: '/static/images/icon/order_new.png',
          page: true,
          permissions: '102'
        }
      },
      {
        path: 'reedit',
        name: 'OrderReEdit',
        components: {order: () => import('../views/order/OrderReEdit.vue')},
        meta: {
          name: '销售退货单',
          icon: '/static/images/icon/order_xt.png',
          page: true,
          permissions: '107'
        }
      },
      {
        path: "print",
        name: "OrderPrint",
        components: {order: () => import("../views/order/OrderPrint.vue")},
        meta: {
          permissions: '105'
        }
      },
      {
        path: 'list',
        name: 'OrderList',
        components: {order: () => import('../views/order/OrderList.vue')},
        meta: {
          name: '管理销售单',
          icon: '/static/images/icon/orderlist.png',
          page: true,
          permissions: '101'
        }
      },
      {
        path: 'calendar',
        name: 'OrderCalendar',
        components: {order: () => import('../views/order/OrderCalendar.vue')},
        meta: {
          name: '销售日历',
          icon: '/static/images/icon/calendar.png',
          page: true,
          permissions: '111'
        }
      },
    ],
  },

  { // 进货模块
    path: '/inbound',
    name: 'Inbound',
    component: () => import('../views/inbound/Inbound.vue'),
    meta: {
      name: '仓库',
      icon: '/static/images/icon/inbound.png',
    },
    children: [
      {
        path: 'edit',
        name: 'InboundEdit',
        components: {inbound: () => import('../views/inbound/InboundEdit.vue')},
        meta: {
          name: '新增进货',
          icon: '/static/images/icon/order_new.png',
          page: true,
          permissions: '502'
        }
      },
      {
        path: 'reedit',
        name: 'InboundReEdit',
        components: {inbound: () => import('../views/inbound/InboundReEdit.vue')},
        meta: {
          name: '仓库退货单',
          icon: '/static/images/icon/order_xt.png',
          page: true,
          permissions: '503'
        }
      },
      {
        path: 'list',
        name: 'InboundList',
        components: {inbound: () => import('../views/inbound/InboundList.vue')},
        meta: {
          name: '管理仓库单',
          icon: '/static/images/icon/orderlist.png',
          page: true,
          permissions: '501'
        }
      },
      {
        path: 'inventory',
        name: 'StockTaking',
        components: {inbound: () => import('../views/inbound/StockTaking.vue')},
        meta: {
          name: '盘点',
          icon: '/static/images/icon/orderlist.png',
          page: true,
          permissions: '507'
        }
      },
      {
        path: 'store',
        name: 'StoreList',
        components: {inbound: () => import('../views/inbound/StoreList.vue')},
        meta: {
          name: '库存',
          icon: '/static/images/icon/orderlist.png',
          page: true,
          permissions: '508'
        }
      },
    ],
  },

  { // 生产模块
    path: '/production',
    name: 'Production',
    component: () => import('../views/production/Production.vue'),
    meta: {
      name: '生产',
      icon: '/static/images/icon/production.png',
    },
    children: [
      {
        path: 'edit',
        name: 'ProductionEdit',
        components: {production: () => import('../views/production/ProductionEdit.vue')},
        meta: {
          name: '新生产单',
          icon: '/static/images/icon/order_new.png',
          page: true,
          permissions: '602'
        }
      },
      {
        path: 'compose',
        name: 'ProductionComposeEdit',
        components: {production: () => import('../views/production/ProductionComposeEdit.vue')},
        meta: {
          name: '产品配料',
          icon: '/static/images/icon/compose.png',
          page: true,
          permissions: '607'
        }
      },
      {
        path: 'list',
        name: 'ProductionList',
        components: {production: () => import('../views/production/ProductionList.vue')},
        meta: {
          name: '管理生产单',
          icon: '/static/images/icon/orderlist.png',
          page: true,
          permissions: '601'
        }
      },
    ],
  },

  { // 产品模块
    path: '/product',
    name: 'Product',
    component: () => import('../views/product/Product.vue'),
    meta: {
      name: '货物',
      icon: '/static/images/icon/product.png',
    },
    redirect: '/product/list',
    children: [
      {
        path: 'edit',
        name: 'ProductEdit',
        components: {product: () => import('../views/product/ProductEdit.vue')},
        meta: {
          name: '添加货物',
          icon: '/static/images/icon/order_new.png',
          page: true,
          permissions: '202'
        }
      },
      {
        path: 'list',
        name: 'ProductList',
        components: {product: () => import('../views/product/ProductList.vue')},
        meta: {
          name: '管理货物',
          icon: '/static/images/icon/orderlist.png',
          page: true,
          permissions: '201'
        }
      },
      {
        path: 'initial',
        name: 'Initial',
        components: {product: () => import('../views/product/Initial.vue')},
        meta: {
          name: '期初库存',
          icon: '/static/images/icon/orderlist.png',
          page: true,
          permissions: '101'
        }
      },
    ]
  },
  { // 客户模块
    path: '/customer',
    name: 'Customer',
    component: () => import('../views/customer/Customer.vue'),
    meta: {
      name: '单位',
      icon: '/static/images/icon/customer.png',
    },
    redirect: '/customer/list',
    children: [
      {
        path: 'edit',
        name: 'CustomerEdit',
        components: {customer: () => import('../views/customer/CustomerEdit.vue')},
        meta: {
          name: '新增单位',
          icon: '/static/images/icon/order_new.png',
          page: true,
          permissions: '302'
        }
      },
      {
        path: 'attributes',
        name: 'CustomerAttributes',
        components: {customer: () => import('../views/customer/CustomerAttributes.vue')},
        meta: {
          name: '专用属性',
          icon: '/static/images/icon/orderlist.png',
          page: false,
          permissions: '306'
        }
      },
      {
        path: 'list',
        name: 'CustomerList',
        components: {customer: () => import('../views/customer/CustomerList.vue')},
        meta: {
          name: '管理单位',
          icon: '/static/images/icon/orderlist.png',
          page: true,
          permissions: '301'
        }
      },
    ],
  },
  { // 用户管理模块
    path: '/user',
    name: 'User',
    component: () => import('../views/user/User.vue'),
    meta: {
      name: '用户',
      icon: '/static/images/icon/user.png',
    },
    redirect: '/user/list',
    children: [
      {
        path: 'list',
        name: 'UserListPage',
        components: {user: () => import('../views/user/UserListPage.vue')},
        meta: {
          name: '用户列表',
          icon: '/static/images/icon/orderlist.png',
          page: true
        },
        redirect: '/user/list',
        children: [
          {
            path: '',
            name: 'UserList',
            components: {UserListPage: () => import('../views/user/UserList.vue')},
            meta: {
              permissions: '401'
            }
          },
          {
            path: 'edit',
            name: 'UserEdit',
            components: {UserListPage: () => import('../views/user/UserEdit.vue')},
            meta: {
              permissions: '402'
            }
          },
        ]
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // 检查登录
  if (to.path === '/login') {
    next();
  } else {
    let token = sessionStorage.getItem('Authorization');
 
    if (token === null || token === '') {
      next('/login');
    } else {
      // 检查权限
      console.log(from.name)
      let tokenJson = JSON.parse(token)
      let permissions = tokenJson.permissions.split(',')
      console.log(permissions)

      if (to.meta.permissions) {
        if (permissions.includes(to.meta.permissions)) {
          next();
        } else {
          toaster.error('无权限操作')
          next({
            name: from.name
          })
        }
      } else {
        next();
      }
    }
  }


  if (to.meta.title) {
    document.title = to.meta.title
  } else {
    document.title = 'GetMoney'
  }
});

export default router
