<template>
  <div class="launcher">
    <div class="launcher-menu">
      <div class="system-time">
        <div class="time">
          <strong>{{ nowTime }}</strong>
          <small>{{ meridiem }}</small>
        </div>
        <div class="date">{{ getdate() }}</div>
      </div>
      <div class="system-user">
        <!-- <div class="message">
          <svg width="32" height="32" viewBox="0 0 24 24" style="fill:white;">
            <path d="M20.1,11.6V8.9c0-4.4-3.6-8-8.1-8S4,4.5,3.9,9L4,11.6c-1.2,0.7-2,2-2,3.4c0,2.1,1.6,3.8,3.7,4c0.1,0,0.2,0,0.3,0H18
            c0.1,0,0.2,0,0.3,0c2-0.2,3.7-1.9,3.7-4C22,13.6,21.3,12.3,20.1,11.6z M19,16.8C18.7,16.9,18.4,17,18,17H6c-0.3,0-0.6-0.1-0.9-0.2
            c-0.6-0.3-1-1-1-1.7c0-0.7,0.4-1.3,1-1.7l0.8-0.5L6,12.8l0-0.3l0-0.9l0-2.6C6,5.6,8.6,3,12,3c3.4,0,6.1,2.7,6.1,6v3.8l0.1,0.1
            l0.8,0.5c0.6,0.4,1,1,1,1.7C20,15.8,19.6,16.4,19,16.8z"></path>
            <path d="M18,17h-1.1c-0.2,0.7-0.5,1.4-0.9,2c-0.9,1.2-2.4,2-4,2s-3.1-0.8-4-2c-0.4-0.6-0.8-1.3-0.9-2H6c-0.3,0-0.6-0.1-0.9-0.2
            c0.1,0.8,0.3,1.5,0.6,2.2c1.1,2.4,3.5,4,6.3,4s5.2-1.6,6.3-4c0.3-0.7,0.5-1.4,0.6-2.2C18.7,16.9,18.4,17,18,17z M18.1,12.6v0.2
            l0.1,0.1C18.2,12.7,18.2,12.6,18.1,12.6z M6,12.4c-0.1,0.1-0.2,0.3-0.2,0.5L6,12.8L6,12.4z"></path>
          </svg>
        </div> -->
        <div class="user d-flex align-items-center">
          <span class="mr-1">{{ username }}</span>
          <img class="avatar avatar-40" src="/static/images/img/user.png">
          <Dropdown>
            <template v-slot:dropdown>
              <button class="btn btn-dropdown" type="button">
                <img class="icon-12" src="/static/images/icon/dropdown.svg">
              </button>
            </template>
            <template v-slot:dropdown-trage>
              <div class="select-box">
                <div class="select-item" @click="logout">
                  退出登录
                </div>
              </div>
            </template>
          </Dropdown>
        </div>
      </div>
    </div>
    
    <div class="launcher-content">
      <div class="grid">

        <!-- <div class="grid-card" v-for="app in apps.slice(2)" :key="app">
          <div class="grid-card-content">
            <router-link :to="{name: app.name}">
              <div class="bg-app">
                <img class="launcher-icon" :src="app.meta.icon">
              </div>
            </router-link>
            <div class="launcher-icon-title">{{ app.meta.name }}</div>
          </div>
        </div> -->

        <div class="grid-card" v-for="app in apps.slice(2)" :key="app">
          <div class="grid-card-content">
              <div class="bg-app" @click="submenu(app.meta.name, app.children)">
                <img class="launcher-icon" :src="app.meta.icon">
              </div>
            <div class="launcher-icon-title">{{ app.meta.name }}</div>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import { useRouter } from 'vue-router'
import Dropdown from '@/components/Dropdown.vue'

export default {
  name: 'Launcher',

  setup() {
    const router = useRouter()
    const apps = router.options.routes
    console.log(apps)
    return {
      apps
    }
  },

  data() {
    return {
      nowTime: '',
      meridiem: '',
      username: JSON.parse(sessionStorage.Authorization).nickname,
      item: [
        {
          title:'yui',
          link:'#'
        }
      ]
    }
  },

  mounted() {
    this.nowTimes()
  },

  beforeUnmount() {
    this.clear()
  },

  components: {
    Dropdown
  },

  methods: {
    toPage:function (e) {
      let data = {
        layout: 'Dashboard',
        app: e
      }
      this.$emit("toPage", data);
    },

    getdate() {
      let year = new Date().getFullYear();
      let month = new Date().getMonth() + 1 < 10 ? '0' + (new Date().getMonth() + 1) : new Date().getMonth() + 1;
      let date = new Date().getDate() < 10 ? '0' + new Date().getDate() : new Date().getDate();
      let week = new Date().getDay();
      let weeks = ['日', '一', '二', '三', '四', '五', '六'];
      let getWeek = '星期' + weeks[week];
      return year + '年' + month + '月' + date + '日' + ' ' + getWeek;
    },

    nowTimes() {
      let hh = new Date().getHours() < 10 ? '0' + new Date().getHours() : new Date().getHours();
      let mm = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes();
      let meridiem = hh < 12 ? ' AM' : ' PM';
      hh = hh <= 12 ? hh : hh - 12;
      this.nowTime = hh + ':' + mm;
      this.meridiem = meridiem;
      setInterval(this.nowTimes, 1000);
      this.clear();
    },

    clear () {
      clearInterval(this.nowTimes);
      this.nowTimes = null;
    },

    logout() {
      sessionStorage.removeItem('Authorization')
      this.$router.push({name: 'Login'})
    },

    submenu(title, params) {
      this.$vfm.show({
        component: 'ShowMenu',
        on: {
          confirm(close) {
            close()
          },
          cancel(close) {
            console.log('取消')
            close()
          },
          closed() {
            console.log('@closed')
          }
        },
        params: {
          title: title,
          app: params
        }
      })
    }
  }

}
</script>

<style scoped>

.launcher-menu {
  display: flex;
  justify-content: space-between;
}

.launcher {
  min-height: 100%;
  color: #ffffff;
  padding: 64px 100px;
  background: linear-gradient(rgba(22,22,22,0.1), rgba(22,22,22, 0.4)), url('/static/images/img/epsbg.jpg') no-repeat center/ cover;
}

.launcher-content {
  padding: 64px 0;
}

.launcher-icon {
  max-width: 96px;
  max-height: 96px;
  width: 100%;
  display: block;
}
.launcher-icon-title {
  font-weight: bold;
  margin-top: 8px;
}

.grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 10px;
    grid-auto-flow: row;
}
.grid-card {
    width: 100%;
    height: 200px;
    /*padding-bottom: 100%;*/
    display: block;
    overflow: hidden;
    position: relative;
    transition: all 0.2s ease;
}
.grid-card-content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: 0;
}

.content-h {
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
}

.bg-app {
  padding: 12px;
  border-radius: 30%;
  background-color: rgba(0, 0, 0, .3);
}

.system-time .time, .system-time .date {
  display: inline-block;
}

.system-time .time strong {
  font-size: 32px;
  font-weight: bold;
}

.system-time .time {
  margin-right: 32px;
}

.system-user {
  display: flex;
  align-items: center;
}

.system-user .message {
  margin-right: 32px;
}

.scale-enter-from,
.scale-leave-to {
  opacity: 0;
  transform: scale(0.9, 0.9);
}

.scale-enter-active,
.scale-leave-active {
  transition: all 0.2s ease;
}

.btn-dropdown {
  padding: 8px;
  margin-left: 8px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, .2);
}

.select-box {
  margin-top: 12px;
  max-width: 180px;
  max-height: 300px;
  min-height: 30px;
  overflow-y: auto;
  position: absolute;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, .1);
  box-shadow: 0 4px 18px rgba(0, 0, 0, .1);
  z-index: 10;
  right: 0;
}

.select-item {
  padding: 8px 24px;
  border-bottom: 1px solid rgba(255, 255, 255, .2);
  cursor: pointer;
}
</style>
