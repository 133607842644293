<template>
<div @contextmenu.prevent style="height: 100%;">
  <modals-container></modals-container>
  <router-view v-slot="{ Component }">
    <transition name="scale" mode="out-in">

        <component :is="Component" />

    </transition>
  </router-view>
</div>
</template>

<style lang="scss">
</style>
